body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  height: 100%;
  width:100vw;
  overscroll-behavior: none;
}

canvas{
  display: block;
  height: 100%;
}

#root{
  height: 100%;
  
}
   
.app{
  background-color: #0a192f;
  max-width: 100vw;
}

.circle{
  width: 400px;
  height: 400px;
  /* background: rgba(0, 0, 0, 0.2); */
  display: inline-flex;
  border-radius: 50%;
  position:relative;
}

.innerCircle{
  width: 200px;
  height: 200px;
  /* background:#ffffff00; */
  border: 2px solid #64ffda;
  border-radius: 50%;
  position: absolute;
  top:50%;
  left:50%;
  margin: -100px 0px 0px -100px;
}

.innerCircleText{
  color:#64ffda;
  display: flex;
  justify-content: center;
  align-items: center;
  height:inherit;
  top:50%;
  left:50%;
}

.menu{
  --d:150px;
  --i:30deg;
  list-style-type: none;
  padding: 0;
  display:grid;
  margin:auto;
}

.menu li{
  grid-area:1/1;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: rotate(var(--r)) translateX(var(--d)) rotate(calc(-1*var(--r)));
}

.menu li:hover{
  height:75px;
  width:75px;
  transform: rotate(var(--r)) translateX(calc(20px + var(--d))) rotate(calc(-1*var(--r)));
}

.menu li:nth-child(1) { --r: calc( 0*var(--i))} 
.menu li:nth-child(2) { --r: calc( 1*var(--i))} 
.menu li:nth-child(3) { --r: calc( 2*var(--i))} 
.menu li:nth-child(4) { --r: calc( 3*var(--i))} 
.menu li:nth-child(5) { --r: calc( 4*var(--i))} 
.menu li:nth-child(6) { --r: calc( 5*var(--i))} 
.menu li:nth-child(7) { --r: calc( 6*var(--i))} 
.menu li:nth-child(8) { --r: calc( 7*var(--i))} 
.menu li:nth-child(9) { --r: calc( 8*var(--i))} 
.menu li:nth-child(10){ --r: calc( 9*var(--i))} 
.menu li:nth-child(11){ --r: calc( 10*var(--i))} 
.menu li:nth-child(12){ --r: calc( 11*var(--i))} 

.menu li img{
  display: block;
  width:50px;
}

.right-gradient{
  position:absolute;
  z-index: 1;

  top:0;
  left:0;

  height:100%;
  width:100%;

  background-image: linear-gradient(to right,
  rgba(255, 255, 255, 0), 95%,
  #0a192f 99%);

}

.left-gradient{
  position:absolute;
  z-index:1;
  
  top:0;
  left:0;

  height:100%;
  width:100%;

  background-image: linear-gradient(to left,
  rgba(255, 255, 255, 0), 95%,
  #0a192f 99%);
}

.contact{
  background-color: #233554;
  height:80vh;
  width:800px;
  border: 9px #64ffda;

}

.contact-form{
  display:block;
  justify-content: center;
  width: 100%;
}

.res{
  height:100vh;
}



/* .landing{
  background-image: url('./assets/solarpunk_banner_by_krannart_deivngj-fullview.jpg');
  background-repeat: no-repeat;
  height: 100vh;
  background-size: 100vw, 100vh;
} */

.landing{
  display:inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  height: 100vh;
}

.text-box{
  width: 60%;
}

.header-text{
  color:#ccd6f6;
  font-family: Verdana, sans-serif;
}

.landing-header-text{
  color:#ccd6f6;
  font-family: Verdana, sans-serif;
  display: flex;
  justify-content:start;
  font-size: 80px;
}

.body-text{
  color: #a8b2d1;
}

.green{
  display: flex;
  color:#64ffda;
  justify-content: start;
  font-size: 20px;
  font:"SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
}

.darkslate{
  color: #8892b0;
  font-size: 60px;
}

.landing-p{
  color: #8892b0;
  font-size: 20px;
}

.landing-text{
  margin:0;
  padding:0;
}

.social-list{
  orientation:left;
  display:inline-flex;
  flex-direction: column;
  align-items: flex-end;
  position:fixed;
  margin-left: 4%;
  height:100vh;
  /* margin-top:55vh; */
}

.social-list::after{
  content:"";
  display:block;
  width:1px;
  height:400px;
  margin:0;
  margin-right: 12px;
  background-color:#a8b2d1;
}

.social-list::before{
  content:"";
  display: block;
  width: 1px;
  height:100px;
  margin:0;
  margin-right:12px;
  background-color: #a8b2d1;
}

.icon-list{
  list-style-type: none;
}

.skill-list{
  column-count:2;
  column-gap: 100px;
  display:flexbox;
  padding-left:200px;
  width:inherit;
  height:inherit;
}

.skill-li{
  color:#a8b2d1;
}

li span{
  position:relative;
  left:5px;
}

.skill-li::marker{
  content:'▹';
  color:#64ffda;
}

.greenText{
  color:#64ffda;
  display:inline;
  font-weight: bold;
}

.max-width{
  max-width: 100vw;
}

/* 
Navy	#0a192f
Light Navy	#112240
Lightest Navy	#233554
Slate	#8892b0
Light Slate	#a8b2d1
Lightest Slate	#ccd6f6
White	#e6f1ff
Green	#64ffda 
*/